import React from 'react';
import {Route, Switch} from "react-router-dom";
import Home from "./pages/home";
import Amphores from "./pages/amphores";
import Page from "./pages/page";

const Routes = (props) => {
  return (
    <Switch>
      <Route path="/amphore/:name">
        <Page type="amphore" />
      </Route>
      <Route path="/amphores">
        <Amphores />
      </Route>
      <Route path="/:name">
        <Page type="page" />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
}

export default Routes;
