import React, {useContext, useState} from 'react';
import parse from 'html-react-parser';
import styled from 'styled-components'
import TranslationContext from "../../context/TranslationContext"
import {Link} from "react-router-dom";
import Sidebar from "../common/sidebar";
import LocaleContext from "../../context/LocaleContext";
import HomeVideo from '../../videos/homepage.mp4';


const Home = (props) => {
  const [lang] = useState("fr");
  const translations = useContext(TranslationContext);
  return (
    <LocaleContext.Provider value={lang}>
      <div className="home container-fluid">
        <div className="row vh-100">
          <Sidebar className="col-3">
            <h3>{parse(translations.home[lang].title)}</h3>
            <p>{parse(translations.home[lang].intro)}</p>
          </Sidebar>
          <div className="col-9">
            <TopRow className="row">
              <div className="col">
                <Link className="full-link amphores-list bg-image" to="/amphores">
                  <video autoPlay muted loop className="homevideo">
                    <source src={HomeVideo} type="video/mp4" />
                  </video>
                  <h2>{parse(translations.home[lang].section_1.title)}</h2>
                  <br/>
                  <span className="mrn-btn">En savoir +</span>
                </Link>
              </div>
            </TopRow>
            <BottomRow className="row">
              <div className="col-3 nopadding">
                <Link className="full-link transport bg-image" to="/transport">
                  <h4>
                    {parse(translations.home[lang].section_5.title)}
                  </h4>
                </Link>
              </div>

              <div className="col-3 nopadding">
                <Link className="full-link flavour bg-image" to="/flavour">
                  <h4 className="flavour-title">
                    {parse(translations.home[lang].section_2.title)}
                  </h4>
                </Link>
              </div>
              <div className="col-3 nopadding">
                <Link className="full-link product bg-image" to="/products">
                  <h4>
                    {parse(translations.home[lang].section_4.title)}
                  </h4>
                </Link>
              </div>
              <div className="col-3 nopadding">
                <Link className="full-link cuisine bg-image no-border" to="/cuisine">
                  <h4>
                    {parse(translations.home[lang].section_3.title)}
                  </h4>
                </Link>
              </div>
            </BottomRow>
          </div>
        </div>
      </div>
    </LocaleContext.Provider>
  );
}

const TopRow = styled.div`
  height: 60%;
  border-bottom: 1px solid #282828;
`;

const BottomRow = styled.div`
  height: 40%;
`;

export default Home;
