import './scss/app.scss';
import { BrowserRouter as Router } from "react-router-dom";
// import Navigation from "./components/features/navigation";
import Routes from "./components/routes";
import translations from './data/translations.json';
import TranslationContext from "./context/TranslationContext"

function App() {
  return (
    <TranslationContext.Provider value={translations}>
    <div className="App">
      <Router>
        <div>
          {/*<Navigation />*/}
          <Routes/>
        </div>
      </Router>
    </div>
    </TranslationContext.Provider>
  );
}

export default App;
