import React, {useContext} from 'react';
import {Link, useParams} from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars-2';
import styled from "styled-components";
import bg from "../../img/home-bg.png";
import TranslationContext from "../../context/TranslationContext";
import LocaleContext from "../../context/LocaleContext";
import parse from "html-react-parser";
import processString from "react-process-string";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import photos from '../config/photos'
import BackArrow from "../../img/back-arrow.svg";

const Page = ({type}) => {
  let { name } = useParams();
  const lang = useContext(LocaleContext);
  const translations = useContext(TranslationContext);
  let photo, copyright, pageContent;

  if (type === 'amphore') {
    photo = translations.amphores[name][lang].photo ?? null;
    copyright = translations.amphores[name][lang].copyright ?? null;
    pageContent = translations.amphores[name][lang] ?? null;
  } else {
    photo = translations.pages[name][lang].photo ?? null;
    copyright = translations.pages[name][lang].copyright ?? null;
    pageContent = translations.pages[name][lang] ?? null;
  }

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: `rgb(255, 255, 255, 1)`
    };
    return (
      <div
        style={{ ...style, ...thumbStyle }}
        {...props}/>
    );
  }

  const renderTrack = ({ style, ...props }) => {
    const thumbStyle = {
      "position": "absolute",
      "right": "0px",
      "width": "20px",
      "bottom": "2px",
      "top": "2px",
      "borderRadius": "3px"
    };
    return (
      <div
        style={{ ...style, ...thumbStyle }}
        {...props}/>
    );
  }

  return (
    <div className="page container-fluid overflow-hidden">
      <div className="row vh-100">
        <div className="col-6 nopadding" style={{position: 'relative'}}>
          <Link className="home-link" to="/">
            <img className="noselect" src={BackArrow} alt=""/>
          </Link>
          {photo &&
            <Left style={{backgroundImage: `url(${photos[photo].default})`}} >
              {copyright &&
                <div className="copyright">{parse(copyright)}</div>
              }
            </Left>
          }
        </div>
        <div className="col-6 nopadding">
          <Right>
            {type === 'amphore' &&
              <span className="type">{translations.amphores_intro[lang].type}</span>
            }
            <h2 className={type === 'amphore' ? 'amphore noselect' : 'noselect'}>{parse(pageContent.title)}</h2>

            <Scrollbars
              universal
              autoHide={false}
              renderTrackVertical={renderTrack}
              renderThumbVertical={renderThumb}
              // renderTrackVertical={props => <div {...props} className="track-vertical"/>}
              renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
              className="mrn-scroll"
              style={{ width: '100%', height: '100%', marginTop: 20, marginBottom: 20, overflowX: 'hidden' }}
            >
              {pageContent.intro &&
                <p className="intro noselect">{parse(pageContent.intro)}</p>
              }
              {pageContent.sections && pageContent.sections.length < 2
                ? (
                  <div style={{marginTop: 20, paddingRight: 20}}>
                    <h4>{parse(pageContent.sections[0].title)}</h4>
                    {pageContent.sections[0].content.map((item,index) => {
                      return <p key={index}>{parse(item)}</p>
                    })}
                    {pageContent.sections[0].photos
                      ? <div style={{ textAlign: 'center'}}>
                        {pageContent.sections[0].photos.map((photo, index) => {
                          return (
                            <div key={`photo_${index}`}>
                              <br />
                              <img className="noselect" style={{maxWidth: '100%', margin: 'auto'}} src={photos[photo.file].default} alt=""/><br />
                              <Legend className="noselect" style={{color: '#ddd', fontSize: '12px', textAlign:'left'}}>{parse(photo.caption)}</Legend>
                              <br /><br />
                            </div>
                          )
                        })}
                      </div>
                      : null
                    }
                  </div>
                )
                : (
                  <div>
                    {pageContent.sections
                      ? <Accordion preExpanded={[1]} allowMultipleExpanded={false} allowZeroExpanded={true}>
                        {pageContent.sections.map((section, index) => {
                          return (
                            <AccordionItem key={`collapse_${index}`} uuid={index+1}>
                              <AccordionItemHeading>
                                <AccordionItemButton>
                                  {parse(section.title)}
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                {section.prefix_photos
                                  ? <div>
                                    {section.prefix_photos.map((photo, index) => {
                                      return (
                                        <div key={`photoprefix_${index}`}>
                                          <img className="noselect" style={{maxWidth: '100%'}} src={photos[photo.file].default} alt=""/><br />
                                          <Legend className="noselect" style={{color: '#ddd', fontSize: '12px',textAlign:'left'}}>{parse(photo.caption)}</Legend>
                                          <br /><br />
                                        </div>
                                      )
                                    })}
                                  </div>
                                  : null
                                }
                                {section.content.map((content, index) => {

                                  let config = [
                                    {
                                    regex: /{([^}]+)}/gim,
                                      fn: (key, result) => {
                                        return result[1];
                                      }
                                    }
                                  ];

                                  let processed = processString(config)(content)

                                  if (typeof processed === 'object' && processed.length > 1) {
                                    processed = Object.keys(processed)
                                      .filter((k) => processed[k] != null)
                                      .reduce((a, k) => ({ ...a, [k]: processed[k] }), {})[1]
                                    let photo_object = processed.split('~')
                                    return (
                                      <p className="noselect" key={`content_${index}`}>
                                        <img className="noselect" style={{maxWidth: '100%', margin: 'auto'}} src={photos[photo_object[0]].default} alt=""/>
                                        <Legend className="noselect" style={{color: '#ddd', fontSize: '12px', textAlign:'left'}}>{parse(photo_object[1])}</Legend>
                                        <br /><br />
                                      </p>
                                    )
                                  } else {
                                    return (
                                      <p className="noselect" key={`content_${index}`}>
                                        {parse(processed[0])}
                                      </p>
                                    )
                                  }


                                })}

                                {section.photos
                                  ? <div style={{ textAlign: 'center'}}>
                                    {section.photos.map((photo, index) => {
                                      return (
                                        <div key={`photo_${index}`}>
                                          <br />
                                          <img className="noselect" style={{maxWidth: '100%', margin: 'auto'}} src={photos[photo.file].default} alt=""/><br />
                                          <Legend className="noselect" style={{color: '#ddd', fontSize: '12px', textAlign:'left'}}>{parse(photo.caption)}</Legend>
                                          <br /><br />
                                        </div>
                                      )
                                    })}
                                  </div>
                                  : null
                                }

                                {section.large_image &&
                                <div className="noselect">
                                  <img className="noselect" style={{maxWidth: '100%'}} src={photos[section.large_image].default} alt=""/>
                                  {section.copyright &&
                                    <Legend className="noselect">
                                      {parse(section.copyright)}
                                    </Legend>
                                  }
                                </div>
                                }
                              </AccordionItemPanel>
                            </AccordionItem>
                          )
                        })}
                      </Accordion>
                      : null
                    }

                  </div>
                )
              }
            </Scrollbars>
          </Right>
        </div>
      </div>
    </div>
  );
}

const Legend = styled.legend`
  font-size: 12px;
  margin-top: 10px;
  font-style: italic;
  border-left: 1px solid #fff;
  padding-left: 10px;
  margin-left: 2px;
  line-height: 20px;
`;

const Left = styled.div`
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  background-position: center;
`;

const Right = styled.div`
    background-image: url(${bg});
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    padding: 100px 100px;
    height: 100%;
  `;

export default Page;
