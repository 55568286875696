import React, {useContext} from 'react';
import Sidebar from "../common/sidebar";
import parse from "html-react-parser";
import TranslationContext from "../../context/TranslationContext";
import LocaleContext from "../../context/LocaleContext";
import {Link} from "react-router-dom";
import BackArrow from '../../img/back-arrow.svg';
import GauloiseVideo from "../../videos/gauloise-rotate.mp4";
import BaltranVideo from "../../videos/baltran-rotate.mp4";
import DresselVideo from "../../videos/dressel-rotate.mp4";

const Amphores = () => {

  const lang = useContext(LocaleContext);
  const translations = useContext(TranslationContext);
  return (
    <div className="amphores container-fluid">
      <div className="row vh-100">
        <Sidebar className="col-3 sidebar">
          <Link className="home-link" to="/">
            <img src={BackArrow} alt=""/>
          </Link>
          <h3>{parse(translations.amphores_intro[lang].title)}</h3>
          <p>{parse(translations.amphores_intro[lang].intro)}</p>
        </Sidebar>
        {Object.entries(translations.amphores).map(([key, item], i) => (
          <div key={i} className={`col-3 model-3d `}>
            {item[lang].alias === 'beltran' &&
            <video autoPlay muted loop className="amphore-video">
              <source src={BaltranVideo} type="video/mp4" />
            </video>
            }
            {item[lang].alias === 'gauloise' &&
            <video autoPlay muted loop className="amphore-video">
              <source src={GauloiseVideo} type="video/mp4" />
            </video>
            }
            {item[lang].alias === 'dressel' &&
            <video autoPlay muted loop className="amphore-video">
              <source src={DresselVideo} type="video/mp4" />
            </video>
            }
            <Link className="full-link" to={`/amphore/${item[lang].alias}`}>
              <span className="type">{translations.amphores_intro[lang].type}</span>
              <h3>{item[lang].name}</h3>
              <span className="mrn-btn">{translations.amphores_intro[lang].cta}</span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}


export default Amphores;
