const photos = {
  amphores_sea: require('../../img/amphores-sea.jpg'),
  transport: require('../../img/transport@2x.jpg'),
  flavours: require('../../img/flavour@2x.jpg'),
  imported_products: require('../../img/imported-products.jpg'),
  gastronomy: require('../../img/fruits.jpg'),
  cuisine: require('../../img/gastronomy@2x.jpg'),

  map: require('../../img/mini/map.png'),
  mecs: require('../../img/mini/mecs.jpg'),
  cheval: require('../../img/mini/dessin-cheval.jpg'),
  relief: require('../../img/mini/relief.jpg'),
  recette: require('../../img/mini/recette.jpg'),
  recette2: require('../../img/mini/recette2.jpg'),
  spoons: require('../../img/mini/spoons.jpg'),
  goblets: require('../../img/mini/goblets.jpg'),
  underwater: require('../../img/mini/underwater.jpg'),
  graffito: require('../../img/hyeroglyph.jpg'),
  amphore: require('../../img/mini/amphore.jpg'),
  carte_production: require('../../img/mini/carte_production.png'),

  baltran: require('../../img/Baltran-Interface.jpg'),
  gauloise: require('../../img/Gauloise-Interface.jpg'),
  dressel: require('../../img/Dressel-Interface.jpg'),

  roman_empire: require('../../img/roman-empire-map.png'),
  roman_route_axes: require('../../img/principaux-axes-routiers.jpg'),
  inscriptions: require('../../img/inscriptions.jpg'),
  places_production: require('../../img/places-of-production.jpg'),
  imported_products_wine: require('../../img/imported_products_wine.jpg'),
  mosaique_garum: require('../../img/mosaique_garum.jpg'),
  recette_huitres: require('../../img/recette-huitres.png'),
  kuchen: require('../../img/kuchen.jpg'),
  saveurs_romains: require('../../img/saveurs-romains.png'),
  coquilles: require('../../img/coquilles.jpg'),

  textiles: require('../../img/textiles.jpg'),
  cuisine_amphores: require('../../img/cuisine-amphores.jpg'),
  underwater2: require('../../img/underwater.jpg'),
  production_site: require('../../img/production-site.jpg'),
  hyeroglyph: require('../../img/hyeroglyph.jpg'),
  sketch: require('../../img/sketch.jpg'),
  troncon: require('../../img/troncon.jpg'),
  troncon_large: require('../../img/troncon-large.jpg'),

}
export default photos;
