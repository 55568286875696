// import React from 'react';
import styled from "styled-components";
import bg from "../../img/home-bg.png";

const Sidebar = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 100px 45px;
  position: relative;
`;

export default Sidebar;
